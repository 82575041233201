<template>
  <DashboardSubsection>
    <ElRow :gutter="20">
      <ElCol :span="12">
        <ElFormItem
          :label="locale.startedAtLabel"
          prop="start"
        >
          <ElDatePicker
            :placeholder="placeholder.start"
            v-model="model.start"
            type="date"
            format="dd.MM.yyyy"
            value-format="dd.MM.yyyy"
            :picker-options="{
              disabledDate: disableBefore,
            }"
          />
        </ElFormItem>
      </ElCol>

      <ElCol :span="12">
        <ElFormItem
          :label="locale.endedAtLabel"
          prop="end"
        >
          <ElDatePicker
            :placeholder="placeholder.end"
            v-model="model.end"
            type="date"
            format="dd.MM.yyyy"
            value-format="dd.MM.yyyy"
            :picker-options="{
              disabledDate: disableAfter,
            }"
          />
        </ElFormItem>
      </ElCol>
    </ElRow>
  </DashboardSubsection>
</template>

<script>
import DashboardSubsection from 'components/layouts/DashboardLayout/components/DashboardSubsection'
import ElCol from 'element/ElCol'
import ElDatePicker from 'element/ElDatePicker'
import ElFormItem from 'element/ElFormItem'
import ElRow from 'element/ElRow'
import moment from 'moment'

export default {
  components: {
    DashboardSubsection,
    ElRow,
    ElCol,
    ElFormItem,
    ElDatePicker,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  computed: {
    locale: ({ $locale }) => ({
      startedAtLabel: $locale('settings.form.schedule.startedAtLabel'),
      endedAtLabel: $locale('settings.form.schedule.endedAtLabel'),
    }),

    placeholder () {
      return {
        start: moment().format('DD.MM.yy'),
        end: moment().add(1, 'day').format('DD.MM.yy'),
      }
    },
  },

  methods: {
    disableBefore (time) {
      if (this.model.end) {
        return time.getTime() > moment(this.model.end, 'DD.MM.yy').format('x')
      }

      return time.getTime() > this.placeholder.end
    },

    disableAfter (time) {
      if (this.model.start) {
        return moment(this.model.start, 'DD.MM.yy').format('x') > time.getTime()
      }

      return this.placeholder.start > time.getTime()
    },
  },
}
</script>
