import { prerollModelToPayloadAdapter } from 'lib/adapters/partner/campaign'
import { Api } from 'lib/api'
import { IPartnerPrerollCampaignStructure } from 'lib/types/partner-campaign/campaign-structure'
import { IPrerollCampaign, IPrerollCampaignDictionaries, IPrerollCampaignFields } from 'lib/types/partner-campaign/preroll'
import { IMessage, IResponseData, IStatus } from 'lib/types/response'

type IResponseCampaign<T> = IStatus & IMessage & { campaign: T }

export const getPrerollCampaignInfo = async (slug: string) => {
  return Api
    .get<IResponseData<IPrerollCampaign>>('partner/campaigns/preroll/info', { slug })
    .then(res => res)
}

export const createPrerollCampaign = async (data: IPrerollCampaignFields) => {
  const formattedData = prerollModelToPayloadAdapter(data)
  return Api
    .post<IResponseCampaign<IPrerollCampaign>>('partner/campaigns/preroll/create', formattedData)
    .then(res => res)
}

export const updatePrerollCampaign = async (data: IPrerollCampaignFields) => {
  const formattedData = prerollModelToPayloadAdapter(data)
  return Api
    .post<IResponseCampaign<IPrerollCampaign>>('partner/campaigns/preroll/update', formattedData)
    .then(res => res)
}

export const getPrerollCampaignStructure = async (slug: string) => {
  return Api
    .get<IResponseData<IPartnerPrerollCampaignStructure>>('partner/campaigns/preroll/structure', { slug })
    .then(res => res)
}

export const getPrerollCampaignDictionaries = async () => {
  const locale = localStorage.getItem('locale') || process.env.VUE_APP_DEFAULT_LOCALE
  return Api
    .get<IStatus & IPrerollCampaignDictionaries>('partner/campaigns/preroll/dictionaries', { locale })
    .then(res => res)
}
