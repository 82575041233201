<template>
  <ElFormItem
    :label="locale.label"
    prop="holding"
  >
    <ElSelect
      :placeholder="locale.placeholder"
      :value="value"
      value-key="id"
      @input="$emit('input', $event)"
      no-data-text="Нет данных"
    >
      <ElOption
        v-for="item in holdings"
        :key="item.id"
        :label="item.title"
        :value="item"
      />
    </ElSelect>
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'

export default {
  components: {
    ElFormItem,
    ElSelect,
    ElOption,
  },

  props: {
    value: VueTypes.oneOfType([VueTypes.object, VueTypes.nullType]),
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('settings.form.holding.label'),
      placeholder: $locale('settings.form.holding.placeholder'),
    }),
    holdings ({ $store }) {
      return $store.getters['dictionaries/campaignDictionaries'].holdings
    },
  },
}
</script>
