import { render, staticRenderFns } from "./SidebarNavNewCampaign.vue?vue&type=template&id=7efce00c&scoped=true&"
import script from "./SidebarNavNewCampaign.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SidebarNavNewCampaign.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SidebarNavNewCampaign.vue?vue&type=style&index=0&id=7efce00c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7efce00c",
  null
  
)

export default component.exports