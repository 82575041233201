export enum ICampaignUrlParams {
  ERID_TOKEN = 'erid_token',
  USER_ID = 'user_id',
  CAMPAIGN_ID = 'campaign_id',
  AD_SET_ID = 'ad_set_id',
  AD_ID = 'ad_id',
  SLUG = 'slug',
  FORMAT_CODE = 'format_code',
  IMPRESSIONS = 'impressions',
  ACTUAL_IMPRESSIONS = 'actual_impressions',
  DEVICE = 'device',
}
