<template>
  <div>
    <ElForm
      v-if="model"
      :model="model"
    >
      <DashboardSection
        class="campaign-form"
        :title="locale.settingsTitle"
      >
        <CampaignSettingsTitle v-model="model.title" />
        <CampaignSettingsDescription v-model="model.description" />
        <CampaignSettingsCategory v-model="model.category" />
        <CampaignSettingsSchedule :model="model" />
        <CampaignSettingsHolding v-model="model.holding" />
        <CampaignSettingsAdvertiser
          v-model="model.advertiser"
          :holding="model.holding"
        />

        <CampaignSettingsAdvice />
      </DashboardSection>
      <DashboardSection
        :title="locale.analyticsTitle"
        class="campaign-analytics"
      >
        <CampaignSettingsURLParams
          :value="model.productUrlAdditionalParams"
          :advertiser="model.advertiser"
        />
        <CampaignSettingsUplifyPixel :model="model" />
      </DashboardSection>
    </ElForm>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import ElForm from 'element/ElForm'
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'

import { usePartnerCampaignStore } from '../../store'

import CampaignSettingsAdvertiser from './components/CampaignSettingsAdvertiser'
import CampaignSettingsAdvice from './components/CampaignSettingsAdvice'
import CampaignSettingsCategory from './components/CampaignSettingsCategory'
import CampaignSettingsDescription from './components/CampaignSettingsDescription'
import CampaignSettingsExternalId from './components/CampaignSettingsExternalId'
import CampaignSettingsHolding from './components/CampaignSettingsHolding'
import CampaignSettingsSchedule from './components/CampaignSettingsSchedule'
import CampaignSettingsStatus from './components/CampaignSettingsStatus'
import CampaignSettingsTimezone from './components/CampaignSettingsTimezone'
import CampaignSettingsTitle from './components/CampaignSettingsTitle'
import CampaignSettingsUplifyPixel from './components/CampaignSettingsUplifyPixel.vue'
import CampaignSettingsURLParams from './components/CampaignSettingsURLParams.vue'

export default {
  components: {
    DashboardSection,
    ElForm,
    CampaignSettingsTitle,
    CampaignSettingsDescription,
    CampaignSettingsCategory,
    CampaignSettingsSchedule,
    CampaignSettingsHolding,
    CampaignSettingsAdvertiser,
    CampaignSettingsStatus,
    CampaignSettingsAdvice,
    CampaignSettingsURLParams,
    CampaignSettingsUplifyPixel,
  },

  computed: {
    locale: ({ $locale }) => ({
      settingsTitle: $locale('settings.title'),
      analyticsTitle: $locale('analytics.title'),
    }),
    ...mapState(usePartnerCampaignStore, ['campaign', 'model']),
  },
}
</script>

<style lang="scss" scoped>
.campaign-form {
  position: relative;

  ::v-deep {
    .advice {
      position: absolute;
      left: calc(100% + 24px);
      top: 32px;
    }
  }
}
</style>
