<template>
  <div class="dashboard-subsection">
    <div class="text-m-bold dashboard-subsection__title">
      {{ title }}
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: VueTypes.string,
  },
}
</script>

<style lang="scss" scoped>
.dashboard-subsection {
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  &__title {
    margin-bottom: 16px;
  }
}
</style>
