<template>
  <ElFormItem
    :label="locale.label"
    prop="title"
  >
    <ElInput
      :placeholder="locale.placeholder"
      :value="value"
      @input="$emit('input', $event)"
    />
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'

export default {
  components: {
    ElFormItem,
    ElInput,
  },

  props: {
    value: VueTypes.string.isRequired,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('settings.form.externalId.label'),
      placeholder: $locale('settings.form.externalId.placeholder'),
    }),
  },
}
</script>
