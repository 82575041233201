<template>
  <Advice :title="locale.title">
    <p>
      <b>{{ locale.name.title }}</b> {{ locale.name.description }}
      <!-- <br>
      <br>
      <b>{{ locale.currency.title }}</b> {{ locale.currency.description }} -->
    </p>
  </Advice>
</template>

<script>
import Advice from 'components/Advice'

export default {
  components: {
    Advice,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('settings.advice.title'),
      name: {
        title: $locale('settings.advice.name.title'),
        description: $locale('settings.advice.name.description'),
      },
      currency: {
        title: $locale('settings.advice.currency.title'),
        description: $locale('settings.advice.currency.description'),
      },
    }),
  },
}
</script>
