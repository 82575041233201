import { performanceModelToPayloadAdapter } from 'lib/adapters/partner/campaign'
import { Api } from 'lib/api'
import { IPartnerPerformanceCampaignStructure } from 'lib/types/partner-campaign/campaign-structure'
import { IPerformanceCampaign, IPerformanceCampaignDictionaries, IPerformanceCampaignFields } from 'lib/types/partner-campaign/performance'
import { IMessage, IResponseData, IStatus } from 'lib/types/response'

type IResponseCampaign<T> = IStatus & IMessage & { campaign: T }

export const getPerformanceCampaignInfo = async (slug: string) => {
  return Api
    .get<IResponseData<IPerformanceCampaign>>('partner/campaigns/performance/info', { slug })
    .then(res => res)
}

export const createPerformanceCampaign = async (data: IPerformanceCampaignFields) => {
  const formattedData = performanceModelToPayloadAdapter(data)
  return Api
    .post<IResponseCampaign<IPerformanceCampaign>>('partner/campaigns/performance/create', formattedData)
    .then(res => res)
}

export const updatePerformanceCampaign = async (data: IPerformanceCampaignFields) => {
  const formattedData = performanceModelToPayloadAdapter(data)
  console.log(data, 'data');
  console.log(formattedData, 'formattedData');
  return Api
    .post<IResponseCampaign<IPerformanceCampaign>>('partner/campaigns/performance/update', formattedData)
    .then(res => res)
}

export const getPerformanceCampaignStructure = async (slug: string) => {
  return Api
    .get<IResponseData<IPartnerPerformanceCampaignStructure>>('partner/campaigns/performance/structure', { slug })
    .then(res => res)
}

export const getPerformanceCampaignDictionaries = async () => {
  const locale = localStorage.getItem('locale') || process.env.VUE_APP_DEFAULT_LOCALE
  return Api
    .get<IStatus & IPerformanceCampaignDictionaries>('partner/campaigns/performance/dictionaries', { locale })
    .then(res => res)
}
