<template>
  <DashboardSection
    class="campaign-actions"
    no-left
  >
    <div class="campaign-actions__inner">
      <div class="campaign-actions__inner-left">
        <ElButton
          type="primary"
          plain
          :disabled="pending"
          @click="onBack"
        >
          {{ backButtonText }}
        </ElButton>
      </div>
      <div class="campaign-actions__inner-right">
        <ElButton
          type="primary"
          :disabled="nextButtonDisabled"
          :loading="pending"
          @click="onNext"
        >
          {{ nextButtonText }}
        </ElButton>
      </div>
    </div>
  </DashboardSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElButton from 'element/ElButton'
import * as sections from 'Partner/pages/PartnerCampaign/consts/sections'
import { mapActions, mapState } from 'pinia'

import { usePartnerCampaignStore } from '../store'

export default {
  components: {
    DashboardSection,
    ElButton,
  },

  props: {
    isEdit: VueTypes.bool,
  },

  computed: {
    locale: ({ $rootLocale }) => $rootLocale('button', true),
    ...mapState(usePartnerCampaignStore, ['campaign', 'pending', 'section', 'campaignType', 'isModelFilled']),
    // campaign: ({ $store }) => $store.getters['campaign/campaign'],
    // pending: ({ $store }) => $store.getters['campaign/pending'],
    // section: ({ $store }) => $store.getters['campaign/section'],
    backButtonText ({ section, locale }) {
      if (section === sections.TYPE) return locale.cancel.static
      if (section === sections.SETTINGS) return locale.back.static
      return '-'
    },
    nextButtonText ({ section, locale }) {
      if (section === sections.TYPE) return locale.next.static
      if (section === sections.SETTINGS) return locale.save
      return '-'
    },
    nextButtonDisabled ({ section, campaignType, isModelFilled }) {
      if (section === sections.TYPE) {
        return !campaignType
      }
      if (section === sections.SETTINGS) {
        return !isModelFilled
      }
      return false
    },
  },

  created () {
    if (this.$route.name === 'edit-campaign') {
      this.dispatchSetSection(sections.SETTINGS)
    }
  },

  methods: {
    ...mapActions(usePartnerCampaignStore, {
      dispatchSetSection: 'setSection',
    }),

    onBack () {
      if (this.section === sections.TYPE || this.$props.isEdit) {
        this.$router.go(-1)
      }
      else if (this.section === sections.SETTINGS) {
        this.dispatchSetSection(sections.TYPE)
      }
    },

    onNext () {
      if (this.section === sections.TYPE) {
        this.dispatchSetSection(sections.SETTINGS)
      }
      else if (this.section === sections.SETTINGS) {
        this.$emit('submit')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.campaign-actions {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  ::v-deep {
    .el-button {
      width: 150px;
    }
  }
}
</style>
