<template>
  <DashboardTitle>
    <div class="headline-2 campaign-title">
      {{ title }}
    </div>
  </DashboardTitle>
</template>

<script>
import DashboardTitle from 'components/layouts/DashboardLayout/components/DashboardTitle'

export default {
  components: {
    DashboardTitle,
  },

  props: {
    campaign: VueTypes.object,
  },

  computed: {
    locale: ({ $locale }) => ({
      title: $locale('defaultName'),
      titleNew: $locale('defaultNameNew'),
      loading: $locale('loading'),
    }),
    title ({ $route, campaign, locale }) {
      if ($route.name === 'create-campaign') {
        return campaign?.title || locale.titleNew
      }
      if (campaign) {
        return campaign?.title || locale.title
      }
      return locale.loading
    },
  },
}
</script>
