<template>
  <ElFormItem
    :label="locale.label"
    prop="category"
  >
    <ElSelect
      :placeholder="locale.placeholder"
      :value="value"
      value-key="id"
      @input="$emit('input', $event)"
      :no-data-text="locale.noData"
    >
      <ElOption
        v-for="item in categories"
        :key="item.id"
        :label="item.title"
        :value="item"
      />
    </ElSelect>
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'

export default {
  components: {
    ElFormItem,
    ElSelect,
    ElOption,
  },

  props: {
    value: VueTypes.oneOfType([VueTypes.object, VueTypes.nullType]),
  },

  computed: {
    categories ({ $store }) {
      return $store.getters['dictionaries/campaignDictionaries'].categories
    },
    locale: ({ $locale }) => ({
      label: $locale('settings.form.category.label'),
      placeholder: $locale('settings.form.category.placeholder'),
      noData: $locale('settings.form.category.noData'),
    }),
  },
}
</script>
