import { awarenessModelToPayloadAdapter } from 'lib/adapters/partner/campaign'
import { Api } from 'lib/api'
import { IAwarenessCampaign, IAwarenessCampaignFields } from 'lib/types/partner-campaign/brand-awareness'
import { IPartnerAwarenessCampaignStructure } from 'lib/types/partner-campaign/campaign-structure'
import { IMessage, IResponseData, IStatus } from 'lib/types/response'

type IResponseCampaign<T> = IStatus & IMessage & { campaign: T }

export const getBrandAwarenessCampaignInfo = async (slug: string) => {
  return Api
    .get<IResponseData<IAwarenessCampaign>>('partner/campaigns/brand_awareness/info', { slug })
    .then(res => res)
}

export const createBrandAwarenessCampaign = async (data: IAwarenessCampaignFields) => {
  const formattedData = awarenessModelToPayloadAdapter(data)
  return Api
    .post<IResponseCampaign<IAwarenessCampaign>>('partner/campaigns/brand_awareness/create', formattedData)
    .then(res => res)
}

export const updateBrandAwarenessCampaign = async (data: IAwarenessCampaignFields) => {
  const formattedData = awarenessModelToPayloadAdapter(data)
  return Api
    .post<IResponseCampaign<IAwarenessCampaign>>('partner/campaigns/brand_awareness/update', formattedData)
    .then(res => res)
}

export const getBrandAwarenessCampaignStructure = async (slug: string) => {
  return Api
    .get<IResponseData<IPartnerAwarenessCampaignStructure>>('partner/campaigns/brand_awareness/structure', { slug })
    .then(res => res)
}
