import { IAwarenessCampaign, IAwarenessCampaignFields } from 'lib/types/partner-campaign/brand-awareness'
import { IPerformanceCampaign, IPerformanceCampaignFields } from 'lib/types/partner-campaign/performance'
import { IPrerollCampaign, IPrerollCampaignFields } from 'lib/types/partner-campaign/preroll'
import { AwarenessCampaignPayloadTo } from 'lib/types/payloads/partner-campaign/brand-awareness'
import { PerformanceCampaignPayloadTo } from 'lib/types/payloads/partner-campaign/performance'
import { PrerollCampaignPayloadTo } from 'lib/types/payloads/partner-campaign/preroll'

export const awarenessPayloadToModelAdapter = (payload: IAwarenessCampaign): IAwarenessCampaignFields => {
  return {
    affiliate_network: payload.affiliate_network,
    slug: payload.slug,
    title: payload.title,
    description: payload.description,
    start: payload.start,
    end: payload.end,
    category: payload.category,
    holding: payload.holding,
    visible: payload.visible,
    timezone: payload.time_zone,
    advertiser: payload.advertiser,
    productUrlAdditionalParams: Array.isArray(payload.product_url_additional_params) ? {} : payload.product_url_additional_params,
  }
}

export const awarenessModelToPayloadAdapter = (model: IAwarenessCampaignFields): AwarenessCampaignPayloadTo => {
  return {
    affiliate_network: model.affiliate_network?.id || null,
    slug: model.slug,
    title: model.title,
    description: model.description,
    category: parseInt(model?.category?.id as string),
    holding: parseInt(model?.holding?.id as string),
    start: model.start,
    end: model.end,
    advertiser: parseInt(model?.advertiser?.id as string),
    visible: model.visible,
    time_zone: model.timezone,
    product_url_additional_params: model.productUrlAdditionalParams,
  }
}

export const performancePayloadToModelAdapter = (payload: IPerformanceCampaign): IPerformanceCampaignFields => {
  return {
    slug: payload.slug,
    title: payload.title,
    description: payload.description,
    external_id: payload.external_id,
    start: payload.start,
    end: payload.end,
    category: payload.category,
    holding: payload.holding,
    advertiser: payload.advertiser,
    visible: payload.visible,
    timezone: payload.time_zone,
    productUrlAdditionalParams: Array.isArray(payload.product_url_additional_params) ? {} : payload.product_url_additional_params,
  }
}

export const prerollPayloadToModelAdapter = (payload: IPrerollCampaign): IPrerollCampaignFields => {
  return {
    slug: payload.slug,
    title: payload.title,
    description: payload.description,
    start: payload.start,
    end: payload.end,
    category: payload.category,
    holding: payload.holding,
    advertiser: payload.advertiser,
    visible: payload.visible,
    productUrlAdditionalParams: Array.isArray(payload.product_url_additional_params) ? {} : payload.product_url_additional_params,
  }
}

export const performanceModelToPayloadAdapter = (model: IPerformanceCampaignFields): PerformanceCampaignPayloadTo => {
  return {
    slug: model.slug,
    title: model.title,
    external_id: model.external_id,
    description: model.description,
    category: parseInt(model?.category?.id as string),
    holding: parseInt(model?.holding?.id as string),
    start: model.start,
    end: model.end,
    advertiser: parseInt(model?.advertiser?.id as string),
    visible: model.visible,
    time_zone: model.timezone,
    product_url_additional_params: model.productUrlAdditionalParams,
  }
}

export const prerollModelToPayloadAdapter = (model: IPrerollCampaignFields): PrerollCampaignPayloadTo => {
  return {
    slug: model.slug,
    title: model.title,
    description: model.description,
    category: parseInt(model?.category?.id as string),
    holding: parseInt(model?.holding?.id as string),
    start: model.start,
    end: model.end,
    advertiser: parseInt(model?.advertiser?.id as string),
    visible: model.visible,
    product_url_additional_params: model.productUrlAdditionalParams,
  }
}
