import { defineStore } from 'pinia'

import {
  awarenessPayloadToModelAdapter,
  performancePayloadToModelAdapter,
  prerollPayloadToModelAdapter,
} from 'lib/adapters/partner/campaign'
import {
  createBrandAwarenessCampaign,
  getBrandAwarenessCampaignInfo,
  updateBrandAwarenessCampaign,
} from 'lib/api/modules/partner/campaign/brand-awareness'
import {
  createPerformanceCampaign,
  getPerformanceCampaignInfo,
  updatePerformanceCampaign,
} from 'lib/api/modules/partner/campaign/performance'
import {
  createPrerollCampaign,
  getPrerollCampaignInfo,
  updatePrerollCampaign,
} from 'lib/api/modules/partner/campaign/preroll'
import { getCampaignTypeBySlug } from 'lib/helpers/getCampaignTypeBySlug'
import { Campaign, CampaignType } from 'lib/types/campaign-type'
import { ICampaignUrlParams } from 'lib/types/campaign-url-params'
import { IAwarenessCampaign, IAwarenessCampaignFields } from 'lib/types/partner-campaign/brand-awareness'
import { IPerformanceCampaign, IPerformanceCampaignFields } from 'lib/types/partner-campaign/performance'
import { IPrerollCampaign, IPrerollCampaignFields } from 'lib/types/partner-campaign/preroll'

import { ESections } from 'Partner/pages/PartnerCampaign/types/sections'

type TPartnerCampaign = IAwarenessCampaign | IPerformanceCampaign | IPrerollCampaign | null
export type TPartnerCampaignModel = IAwarenessCampaignFields | IPerformanceCampaignFields | IPrerollCampaignFields | null

interface IPartnerCampaignState {
  campaign: TPartnerCampaign
  model: TPartnerCampaignModel
  campaignType: CampaignType | null
  section: ESections
  pending: boolean
}

export type TParamURLBlock = {
  key: string
  param: ICampaignUrlParams | null
  name: string | null
}

export const usePartnerCampaignStore = defineStore({
  id: 'partnerCampaign',

  state: (): IPartnerCampaignState => ({
    campaign: null,
    model: null,
    section: ESections.TYPE,
    campaignType: null,
    pending: false,
  }),

  getters: {
    isModelFilled (): boolean {
      switch (this.campaignType) {
        case Campaign.PERFORMANCE:
        // @ts-expect-error: external_id
          return Boolean(this.model && this.model.title && this.model.description && this.model.external_id && this.model.start && this.model.category && this.model.end && this.model.holding && this.model.advertiser)
        case Campaign.PREROLL:
          return Boolean(this.model && this.model.title && this.model.description && this.model.start && this.model.category && this.model.end && this.model.holding && this.model.advertiser)

        default:
          return Boolean(this.model && this.model.title && this.model.description && this.model.start && this.model.category && this.model.end && this.model.holding && this.model.advertiser)
      }
    },
  },

  actions: {
    setModel (model: TPartnerCampaignModel) {
      this.model = model
    },

    async fetchCampaign (slug: string) {
      const type = getCampaignTypeBySlug(slug)

      if (type === Campaign.PERFORMANCE) {
        const res = await getPerformanceCampaignInfo(slug)
        this.campaign = res.data
        this.campaignType = type
        this.model = performancePayloadToModelAdapter(res.data)
        if ('timezone' in this.model) {
          this.model.timezone = 0
        }
      }

      if (type === Campaign.PREROLL) {
        const res = await getPrerollCampaignInfo(slug)
        this.campaign = res.data
        this.campaignType = type
        this.model = prerollPayloadToModelAdapter(res.data)
      }

      else {
        const res = await getBrandAwarenessCampaignInfo(slug)
        this.campaign = res.data
        this.campaignType = type
        this.model = awarenessPayloadToModelAdapter(res.data as IAwarenessCampaign)
      }
    },

    async createCampaign () {
      if (this.campaignType === Campaign.PERFORMANCE) {
        const res = await this.createPerformanceCampaign()
        return res
      }
      if (this.campaignType === Campaign.PREROLL) {
        const res = await this.createPrerollCampaign()
        return res
      }
      else {
        const res = await this.createBrandAwarenessCampaign()
        return res
      }
    },

    async updateCampaign () {
      if (this.campaignType === Campaign.BRAND_AWARENESS) {
        const res = await this.updateBrandAwarenessCampaign()
        return res
      }
      if (this.campaignType === Campaign.PERFORMANCE) {
        const res = await this.updatePerformanceCampaign()
        return res
      }
      if (this.campaignType === Campaign.PREROLL) {
        const res = await this.updatePrerollCampaign()
        return res
      }
    },

    async createBrandAwarenessCampaign () {
      try {
        this.pending = true
        const res = await createBrandAwarenessCampaign(this.model as IAwarenessCampaignFields)
        this.campaign = res.campaign
        return res.campaign
      }
      finally {
        this.pending = false
      }
    },

    async updateBrandAwarenessCampaign () {
      try {
        this.pending = true
        const res = await updateBrandAwarenessCampaign(this.model as IAwarenessCampaignFields)
        this.campaign = res.campaign
        return res.campaign
      }
      finally {
        this.pending = false
      }
    },

    async createPerformanceCampaign () {
      try {
        this.pending = true
        if (this.model && 'timezone' in this.model) {
          this.model.timezone = 0
        }
        const res = await createPerformanceCampaign(this.model as IPerformanceCampaignFields)
        this.campaign = res.campaign
        return res.campaign
      }
      finally {
        this.pending = false
      }
    },

    async updatePerformanceCampaign () {
      try {
        this.pending = true
        if (this.model && 'timezone' in this.model) {
          this.model.timezone = 0
        }
        const res = await updatePerformanceCampaign(this.model as IPerformanceCampaignFields)
        this.campaign = res.campaign
        return res.campaign
      }
      finally {
        this.pending = false
      }
    },

    async createPrerollCampaign () {
      try {
        this.pending = true
        const res = await createPrerollCampaign(this.model as IPrerollCampaignFields)
        this.campaign = res.campaign
        return res.campaign
      }
      finally {
        this.pending = false
      }
    },

    async updatePrerollCampaign () {
      try {
        this.pending = true
        const res = await updatePrerollCampaign(this.model as IPrerollCampaignFields)
        this.campaign = res.campaign
        return res.campaign
      }
      finally {
        this.pending = false
      }
    },

    setSection (data: ESections) {
      this.section = data
    },

    reset () {
      this.section = ESections.TYPE
      this.campaign = null
      this.model = null
      this.campaignType = null
    },

    updateModelUrlParams (params: TParamURLBlock[]) {
      if (!this.model) {
        return
      }

      this.model.productUrlAdditionalParams = {}
      params.forEach(param => {
        if (this.model && param.name && param.param) {
          this.model.productUrlAdditionalParams[param.name] = param.param
        }
      })
    },
  },
})
