// RegExp (BA|BL|T|C)-(CMP|GRP|CRV)-((V|P|C|L)-)*(\d+)

export enum Types {
  BA = 'brand_awareness',
  PF = 'performance',
  VOD = 'preroll',
}

export enum Kinds {
  CMP = 'campaign',
  GRP = 'group',
  CRV = 'creative',
}

export enum Formats {
  V = 'video',
  L = 'leaderboard',
  P = 'pip_video',
  C = 'custom',
  YFS = 'yandex_fs',
  YT = 'yandex_text',
}

interface ParsedSlug {
  kind: Kinds
  type: Types
  format: Formats
}

type SplittedSlug = [
  keyof typeof Types,
  keyof typeof Kinds,
  keyof typeof Formats
]

export default (slug: string): ParsedSlug => {
  if (typeof slug !== 'string') {
    throw new Error('Slug is not string')
  }

  const [type, kind, format]: SplittedSlug = slug.split('-') as SplittedSlug

  return {
    kind: Kinds[kind],
    type: Types[type],
    format: Formats[format],
  }
}
